<template>
	<section class="mx-4 mt-4">
		<div class="row mx-0 pl-3 mt-3 mb-5">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ name: 'biblioteca.carpetas'}">
					Gestion Administrativa
				</el-breadcrumb-item>
				<el-breadcrumb-item>Privacidad</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="row mx-0">
			<div class="col-12" />
			<div class="col-lg-5 col-md-6 col-sm-12">
				<ValidationObserver ref="formPrincipal" class="w-100">
					<div class="row mx-0 ">
						<div class="col-12 pl-0 pr-3 pl-0">
							<p class="f-14 text-black">
								Define la privacidad de la carpeta.
							</p>
							<ValidationProvider rules="required" name="privacidad" v-slot="{ errors }" tag="div">
								<div class=" f-14 mt-1 my-auto">
									<label class="d-middle mr-3" for="">
										<input v-model="checked" :value="11" type="radio" class="option-input opt-gris radio mr-2" @change="editarPrivacidad">
										<div class=" text-black f-18">
											<i class="icon-lock f-15 text-black mx-2" />
											Privado
										</div>
									</label>
									<p class="f-14 mx-4 mb-2 ">Solo tu puedes ver esta carpeta y sus archivos</p>
									<label class="d-middle mr-3" for="">
										<!-- <input v-model="checked" :value="2" type="radio" class="option-input opt-gris radio mr-2 mt-3" @change="editarPrivacidad"> -->
										<div class=" text-black f-18 mx-3 mt-2">
											<i class="icon-group-outline f-15 text-black mx-2" />
											Público
										</div>
									</label>
									<p class="f-14 mx-4 mb-2">Todos los residentes de este conjunto pueden ver la carpeta y sus archivos</p>
									<div class="mx-4 row">
                                        <input v-model="checked" :value="21" type="radio" class="option-input opt-gris radio mr-2" @change="editarPrivacidad"/> Residentes y propietarios
                                    </div>
                                    <div class="mx-4 row mt-2">
                                        <input v-model="checked" :value="23" type="radio" class="option-input opt-gris radio mr-2" @change="editarPrivacidad"/> Solo residentes
                                    </div>
                                    <div class="mx-4 row mt-2">
                                        <input v-model="checked" :value="22" type="radio" class="option-input opt-gris radio mr-2" @change="editarPrivacidad"/> Solo propietarios
                                    </div>
									<label class="d-middle mr-3" for="">
										<!-- <input v-model="checked" :value="3" type="radio" class="option-input opt-gris radio mr-2 mt-4" @change="editarPrivacidad"> -->
										<div class=" text-black f-18 mx-3 mt-3">
											<i class="icon-account-plus f-15 text-black mx-2" />
											Compartir
										</div>
									</label>
									<p class="f-14 mx-4 mb-2">Solo las viviendas o agrupaciones seleccionadas pueden ver la carpeta y sus archivos</p>
									<div class="mx-4 row">
                                        <input v-model="checked" :value="31" type="radio" class="option-input opt-gris radio mr-2" @change="editarPrivacidad"/> Residentes y propietarios
                                    </div>
                                    <div class="mx-4 row mt-2">
                                        <input v-model="checked" :value="33" type="radio" class="option-input opt-gris radio mr-2" @change="editarPrivacidad"/> Solo residentes
                                    </div>
                                    <div class="mx-4 row mt-2">
                                        <input v-model="checked" :value="32" type="radio" class="option-input opt-gris radio mr-2" @change="editarPrivacidad"/> Solo propietarios
                                    </div>
								</div>
								<p class="text-danger f-10">{{ errors[0] }}</p>
							</ValidationProvider>
						</div>
					</div>
				</validationobserver>
			</div>
			<div class="col-lg-3 col-md-4 col-sm-12 ml-4" v-if="checked > 30">
				<p class="col-auto f-20 f-500 mb-3 px-0">Compartir carpeta</p>
				<p class="f-12 text-whitesmoke pl-3">
					Seleccione una agrupación
				</p>
				<div @click="compartirCarpeta">
					<el-input v-model="prueba" :disable="true" placeholder="Seleccionar" class="w-100" size="small"
						suffix-icon="el-icon-arrow-down" />
				</div>
				<p class="d-flex justify-content-end mt-4 mb-1">{{ totalViviendas }} viviendas seleccionadas</p>
				<div v-for="(agrupacion, index) in agrupaciones" :key="index">
					<div class="d-middle f-15 justify-content-between px-2 py-1 mb-3 br-7 cr-pointer" style="background: #F5F5F5" @click="agrupacion.show = !agrupacion.show">
						<p class="text-86 f-300 f-14">{{ agrupacion.nombre}}</p>
						<i class=" text-whitesmoke f-18 ml-auto cr-pointer" :class="agrupacion.show ? 'icon-chevron-up' : 'icon-chevron-down'" />
					</div>
					<Transition>
						<div v-if="agrupacion.show" class="mb-3">
							<p class="d-flex justify-content-end f-14 text-general px-2 cr-pointer" @click="quitarApartamentosPorTorre(agrupacion.id)">Quitar todos</p>
							<div v-for="(vivienda, idx) in agrupacion.viviendas" :key="idx" class="border-bottom d-middle justify-content-between py-2 mx-2">
								<span class="f-14 f-300 ">{{ vivienda.nombre }}</span>
								<i class="icon-close text-whitesmoke f-11 ml-auto cr-pointer" @click="quitarApartamento(vivienda.id)"/>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</div>
		<modal-compartir-biblioteca-editar ref="modalCompartirBibliotecaEditar" @compartir="addViviendas" />
	</section>
</template>


<script>
import moment from 'moment'
import Carpetas from '~/services/bibliotecas/bibliotecas-carpetas'
export default {
	components: {
		modalCompartirBibliotecaEditar: () => import('./partials/modalCompartirBibliotecaEditar'),
	},
	data() {
		return {
			checked: false,
			nombre: '',
			agrupaciones: [],
			prueba: ''
		}
	},
	mounted() {
		this.checked = this.$route.params.privacidad;
		this.getViviendasAcceso();
	},
	computed: {
		totalViviendas(){
			const total = this.agrupaciones.reduce((acc, curr) => {
				if(curr.viviendas && curr.viviendas.length){
					acc = acc + curr.viviendas.length
				} 
				return acc
			}, 0)
			return total
		}
	},
	methods: {
		compartirCarpeta() {
			this.$refs.modalCompartirBibliotecaEditar.toggle()
		},
		addViviendas(item) {
			let idx = this.agrupaciones.map(element => element.id).indexOf(item.id)
			if (idx === -1) {
				this.agrupaciones.push(item)
			} else {
				this.agrupaciones[idx].viviendas = item.viviendas;
			}
			this.editViviendasAcceso()
		},
		deleteVivienda(indexHijo, indexPadre) {
			console.log(123);
		},
		deleteAgrupacion(index) {
			this.agrupaciones.splice(index, 1);
		},
		filtrarIdsViviendas() {
			let ids = [];
			
			this.agrupaciones.forEach((element, index) => {
				if (element.viviendas) {
					console.log(element.viviendas);
					let data = ids.concat(element.viviendas.map(({ id }) => id))
					ids = data;
				} else {
					ids.push(element.id)
				}
			});
			return ids;
		},
		async quitarApartamento(vivienda_id){
			try {
				let acc = []
				this.agrupaciones.forEach((agrupacion, index) => {
					const viviendas = agrupacion.viviendas.filter(vivienda => vivienda.id != vivienda_id);
					agrupacion.viviendas = viviendas;
					acc.push(...viviendas.map(el => el.id))
				})

				const payload = {
					idCarpeta: this.$route.params.idCarpeta,
					viviendas: acc
				}

				await Carpetas.postViviendasAcceso(payload);

			} catch (error) {
				this.errorCatch(error);
			}
		},
		async quitarApartamentosPorTorre(agrupacion_id){
			try {
				const viviendas = this.agrupaciones.reduce((acc, curr) => {
					if(curr.id != agrupacion_id){
						acc.push(...curr.viviendas.map(el => el.id))
					}
					return acc;
				}, [])

				const payload = {
					idCarpeta: this.$route.params.idCarpeta,
					viviendas
				}

				await Carpetas.postViviendasAcceso(payload);

				this.getViviendasAcceso();
			} catch (error) {
				this.errorCatch(error);
			}
		},
		async editarPrivacidad() {
			try {

				const payload = {
					tipo: this.checked,
					idCarpeta: this.$route.params.idCarpeta,
				}
				const { data } = await Carpetas.editarPrivacidadCarpeta(payload);
				this.$router.push({ name:'biblioteca.carpetas.editar.privacidad', params: { idCarpeta: payload.idCarpeta, privacidad: data.tipo }})
				this.notificacion('Éxito', data.mensaje, 'success');

			} catch (error) {
				return this.errorCatch(error);
			}
		},
		async getViviendasAcceso() {
			try {
				const { data } = await Carpetas.getviviendasAcceso(this.$route.params.idCarpeta)
				this.agrupaciones = data.viviendasAcceso.map((el) => ({...el, show: false }))
			} catch (error) {
				return this.errorCatch(error);
			}
		},
		async editViviendasAcceso() {
			try {
				let viviendas = this.filtrarIdsViviendas();

				const payload = {
					viviendas: viviendas,
					idCarpeta: this.$route.params.idCarpeta,
				}
				const { data } = await Carpetas.postViviendasAcceso(payload)
				this.getViviendasAcceso()
				this.notificacion('Éxito', data.mensaje, 'success')
			} catch (error) {
				return this.errorCatch(error);
			}
		}


	}
}


</script>
<style lang="scss" scoped>
.cropper-style {
	height: 300px;
	width: 300px;
	background-color: #f1f1f1;
	color: #868686;
	border-radius: 8px;
}

.box-ventana {
	width: 200px;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #DFE4E8;
	border-radius: 4px;
}

.label {
	color: #5F6D84;
	font-size: 12px;
	padding-left: 15px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>